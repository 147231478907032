<template>
  <section class="access-object-invalid">
    <div class="access-object-invalid__wrapper">
      <img
        class="access-object-invalid__img"
        src="../../assets/images/access-error.png"
        width="200"
        height="200"
        alt="Изображение ошибка доступа"
      />
    </div>
    <h2 class="access-object-invalid__header">Пропуск недействителен</h2>
    <p class="access-object-invalid__description">
      Срок действия этого пропуска истёк или <br />
      вам анулировали доступ.
    </p>
  </section>
</template>

<style lang="scss">
.access-object-invalid__wrapper {
  padding-top: 84px;
  padding-bottom: 32px;
}

.access-object-invalid__img {
  display: block;
  margin: 0 auto;
}

.access-object-invalid__header {
  font-style: 17px;
  margin-bottom: 24px;
}

.access-object-invalid__description {
  font-size: 15px;
}
</style>
