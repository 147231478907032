<template>
  <section class="card">
    <slot></slot>
  </section>
</template>

<style lang="scss">
.card {
  padding: 16px;

  border-radius: 16px;
  background-color: var(--surface);
}
</style>
