<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 23.0002C9.82441 23.0002 7.69767 22.3551 5.88873 21.1464C4.07979 19.9377 2.66989 18.2197 1.83733 16.2097C1.00477 14.1997 0.786929 11.988 1.21137 9.85419C1.6358 7.7204 2.68345 5.76039 4.22183 4.22201C5.76021 2.68364 7.72022 1.63599 9.85401 1.21155C11.9878 0.787113 14.1995 1.00495 16.2095 1.83751C18.2195 2.67008 19.9375 4.07997 21.1462 5.88892C22.3549 7.69786 23 9.82459 23 12.0002C22.9967 14.9166 21.8367 17.7125 19.7745 19.7747C17.7124 21.8369 14.9164 22.9969 12 23.0002ZM12 3.00019C10.22 3.00019 8.47992 3.52803 6.99987 4.51696C5.51983 5.50589 4.36628 6.9115 3.68509 8.55604C3.0039 10.2006 2.82567 12.0102 3.17294 13.756C3.5202 15.5018 4.37737 17.1055 5.63604 18.3641C6.89472 19.6228 8.49836 20.48 10.2442 20.8273C11.99 21.1745 13.7996 20.9963 15.4442 20.3151C17.0887 19.6339 18.4943 18.4804 19.4832 17.0003C20.4722 15.5203 21 13.7802 21 12.0002C20.9972 9.61409 20.0481 7.32651 18.3609 5.63929C16.6737 3.95206 14.3861 3.00296 12 3.00019ZM13.0098 16.0002C13.0098 15.8689 12.984 15.7388 12.9337 15.6175C12.8835 15.4961 12.8098 15.3859 12.717 15.293C12.6241 15.2001 12.5138 15.1265 12.3925 15.0762C12.2712 15.026 12.1411 15.0002 12.0098 15.0002H12C11.8027 15.0021 11.6103 15.0624 11.4471 15.1734C11.284 15.2845 11.1573 15.4413 11.0831 15.6242C11.0089 15.8071 10.9905 16.0079 11.0302 16.2012C11.0699 16.3945 11.1659 16.5718 11.3061 16.7107C11.4463 16.8496 11.6245 16.9438 11.8182 16.9816C12.012 17.0194 12.2125 16.9991 12.3947 16.9231C12.5768 16.8471 12.7324 16.719 12.8419 16.5547C12.9514 16.3905 13.0098 16.1976 13.0098 16.0002ZM13 12.0002V8.00019C13 7.73497 12.8946 7.48062 12.7071 7.29308C12.5196 7.10555 12.2652 7.00019 12 7.00019C11.7348 7.00019 11.4804 7.10555 11.2929 7.29308C11.1054 7.48062 11 7.73497 11 8.00019V12.0002C11 12.2654 11.1054 12.5198 11.2929 12.7073C11.4804 12.8948 11.7348 13.0002 12 13.0002C12.2652 13.0002 12.5196 12.8948 12.7071 12.7073C12.8946 12.5198 13 12.2654 13 12.0002Z"
      fill="#FF897D"
    />
  </svg>
</template>
